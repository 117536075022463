import React from 'react';
import { Box, Grid, Typography, Card, CardContent, Button, Paper, MenuItem, FormControl, Select, IconButton, InputLabel, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Slider from "react-slick";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreIcon from '@mui/icons-material/Store';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Visibility, FilterList } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

// Dummy data for the chart and table
const marketTrendsData = {
  labels: Array.from({ length: 30 }, (_, i) => i + 1),
  datasets: [
    {
      label: 'Financed Amount',
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 1000) + 200), // Random amounts between 200 and 1200
      borderColor: 'green',
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
      tension: 0.4,
    },
    {
      label: 'Settled Invoices',
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 1000) + 100), // Random amounts between 100 and 1100
      borderColor: 'red',
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
      tension: 0.4,
    },
  ],
};


const statsData = [
  {
    value: '#558',
    label: 'Suppliers',
    icon: <GroupIcon style={{ fontSize: 50, color: '#5c6bc0' }} />,
  },
  {
    value: '#2,000,000',
    label: 'SKU’s Delivered',
    icon: <LocalShippingIcon style={{ fontSize: 50, color: '#ffca28' }} />,
  },
  {
    value: '#5000',
    label: 'Retailers',
    icon: <StoreIcon style={{ fontSize: 50, color: '#4caf50' }} />,
  },
  {
    value: '₹ 5,127,558',
    label: 'Inventory Sold',
    icon: <InventoryIcon style={{ fontSize: 50, color: '#26a69a' }} />,
  },
  {
    value: '#7,50,000',
    label: 'Customers Purchased',
    icon: <ShoppingCartIcon style={{ fontSize: 50, color: '#ff7043' }} />,
  },
];

const barChartData = {
  labels: ['Financed Amount', 'Settled Invoices'],
  datasets: [
    {
      label: 'Percentage',
      data: [75, 25],
      backgroundColor: ['#00FF00', '#87CEFA'],
    },
  ],
};

const lineChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Financed Amount',
      data: [700, 600, 800, 750, 820, 720, 850, 900, 750, 850, 800, 880],
      borderColor: 'green',
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
      tension: 0.4,
    },
    {
      label: 'Settled Invoices',
      data: [500, 480, 550, 520, 500, 600, 580, 550, 600, 650, 700, 680],
      borderColor: 'blue',
      backgroundColor: 'rgba(135, 206, 250, 0.1)',
      tension: 0.4,
    },
  ],
};

// Bar and Line chart options
const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      beginAtZero: true,
    },
  },
};


const financingOffers = [
  {
    id: 1,
    name: 'Sayeed Afzal',
    type: 'Retailer',
    offerId: '#151616124',
    date: '16 Nov, 2021',
    totalAmount: '₹2,127,120',
    interestRate: '15%',
    tenure: '2 Years',
    status: 'ACCEPTED',
    statusColor: 'success',
  },
  {
    id: 2,
    name: 'Sayeed Afzal',
    type: 'Supplier',
    offerId: '#151616124',
    date: '16 Nov, 2021',
    totalAmount: '₹2,127,120',
    settlingAmount: '90%',
    clearanceTime: '3 Months',
    status: 'DECLINED',
    statusColor: 'error',
  },
  {
    id: 3,
    name: 'Sayeed Afzal',
    type: 'Supplier',
    offerId: '#151616124',
    date: '16 Nov, 2021',
    totalAmount: '₹2,127,120',
    settlingAmount: '90%',
    clearanceTime: '3 Months',
    status: 'DECLINED',
    statusColor: 'error',
  },
];

// Mock data for the invoices
const rows = [
  { id: 1, invoiceId: "#3245", supplier: "Arya Singh", retailer: "Mommin Mohi", orderDate: "12.01.2024", dueDate: "12.01.2024", amount: "₹2,558", orderStatus: "Delivered" },
  { id: 2, invoiceId: "#3245", supplier: "Arya Singh", retailer: "Mommin Mohi", orderDate: "11.01.2024", dueDate: "11.01.2024", amount: "₹2,189", orderStatus: "Delivered" },
  { id: 3, invoiceId: "#3245", supplier: "Arya Singh", retailer: "Mommin Mohi", orderDate: "07.01.2024", dueDate: "07.01.2024", amount: "₹1,098", orderStatus: "Pending" },
  // Add more rows as needed...
];

const columns = [
  { field: "invoiceId", headerName: "Invoice ID", flex: 1 },
  { field: "supplier", headerName: "Supplier", flex: 1 },
  { field: "retailer", headerName: "Retailer", flex: 1 },
  { field: "orderDate", headerName: "Order Date", flex: 1 },
  { field: "dueDate", headerName: "Due Date", flex: 1 },
  { field: "amount", headerName: "Amount", flex: 1 },
  {
    field: "orderStatus",
    headerName: "Order Status",
    flex: 1,
    renderCell: (params) => (
      <Typography
        sx={{
          color: params.value === "Delivered" ? "green" : "red",
          fontWeight: "bold",
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    renderCell: () => (
      <IconButton>
        <Visibility style={{color:"#555555"}}/>
      </IconButton>
    ),
  }
];

const marketTrendsOptions = {
  responsive: true,
  plugins: {
    legend: { position: 'bottom', labels: { usePointStyle: true, boxWidth: 10, padding: 20 } },
    tooltip: {
      callbacks: {
        labelColor: function (context) {
          return {
            backgroundColor: context.dataset.borderColor,
            borderColor: context.dataset.borderColor,
            borderWidth: 2,
            borderRadius: 2,
          };
        },
      },
    },
  },
  scales: {
    x: { title: { display: true, text: 'Duration (Days)' }, grid: { drawOnChartArea: false } },
    y: { title: { display: true, text: 'Value (in Lacs)' }, beginAtZero: true, ticks: { stepSize: 200 } },
  },
};

export default function Dashboard() {

  const [filter, setFilter] = React.useState("All");
  const [dateRange, setDateRange] = React.useState([null, null]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };


  const [month, setMonth] = React.useState('December');
  const [timePeriod, setTimePeriod] = React.useState('Last 7 days');
  const [yearPeriod, setYearPeriod] = React.useState('Last Year');

  const handleTimeChange = (event) => {
    setTimePeriod(event.target.value);
  };

  const handleYearChange = (event) => {
    setYearPeriod(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const sliderSettings = {
    dots: true,               // Show pagination dots
    infinite: false,           // Enable infinite scrolling
    speed: 500,               // Transition speed between slides
    slidesToShow: 2,          // Number of cards shown in one row
    slidesToScroll: 1,        // Scroll one card at a time
    arrows: true,             // Show next/previous arrows
  };

  return (
    <Box p={3} sx={{ backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
      {/* Stats Overview */}
      <Box sx={{ padding: 'auto', width: '100%', margin: 'auto' }}>
        <Grid container spacing={3} style={{ flex: 'row', justifyContent: 'space-around' }}>
          {statsData.map((stat, index) => (
            <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
              <Box
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  color: '#404040',
                  border: 'none',
                  margin: 'auto',
                  padding: 2,
                }}
              >
                <Typography variant="h4">{stat.value}</Typography>
                <Typography variant="subtitle1" style={{ color: '#404040' }}>
                  {stat.label}
                </Typography>
                {stat.icon}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Financing Market Trends */}
      <Box mt={4} sx={{ backgroundColor: 'transparent' }}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" style={{ color: '#404040', fontFamily: 'Noto Sans', fontSize: '18px' }}>
              Financing Market Trends
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select
                value={month}
                onChange={handleMonthChange}
                style={{
                  color: '#80A0FF',
                  border: '1px solid #80A0FF',
                  fontFamily: 'Noto Sans',
                  fontSize: '18px',
                }}
              >
                <MenuItem value="January">January</MenuItem>
                <MenuItem value="February">February</MenuItem>
                <MenuItem value="March">March</MenuItem>
                <MenuItem value="April">April</MenuItem>
                <MenuItem value="May">May</MenuItem>
                <MenuItem value="June">June</MenuItem>
                <MenuItem value="July">July</MenuItem>
                <MenuItem value="August">August</MenuItem>
                <MenuItem value="September">September</MenuItem>
                <MenuItem value="October">October</MenuItem>
                <MenuItem value="November">November</MenuItem>
                <MenuItem value="December">December</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            style={{
              background: 'linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)',
              border: '1px solid #F7F7F7',
              boxShadow: `10px 10px 40px 0px rgba(210, 210, 210, 0.6), -10px -10px 20px 0px #FFFFFF`,
              padding: '20px',
            }}
          >
            <Line data={marketTrendsData} options={marketTrendsOptions} />
          </Box>
        </Box>
      </Box>

      {/* Financing Offers Slider */}
      {/* <Box mt={4} sx={{ width: "100%" }}>
      <Paper elevation={3}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Recent Financing Offers</Typography>
            <Button variant="contained" color="primary">VIEW ALL</Button>
          </Box>

          <Slider {...sliderSettings}>
            {financingOffers.map((offer) => (
              <Box key={offer.id} p={2} sx={{ width: "100%" }}>
                <Card sx={{ height: '100%', backgroundColor: '#1e1e1e', color: '#fff' }}>
                  <CardContent>
                    <Typography variant="h6">{offer.name}</Typography>
                    <Typography variant="body2">{offer.type}</Typography>
                    <Typography variant="body2">ID: {offer.offerId}</Typography>
                    <Typography variant="body2">{offer.date}</Typography>
                    <Typography variant="body2" mt={2}>
                      Total Amount: {offer.totalAmount}
                    </Typography>
                    {offer.interestRate && (
                      <Typography variant="body2">Interest Rate: {offer.interestRate}</Typography>
                    )}
                    {offer.tenure && (
                      <Typography variant="body2">Tenure (Per Month EMI): {offer.tenure}</Typography>
                    )}
                    {offer.settlingAmount && (
                      <Typography variant="body2">Settling Amount: {offer.settlingAmount}</Typography>
                    )}
                    {offer.clearanceTime && (
                      <Typography variant="body2">Clearance Time: {offer.clearanceTime}</Typography>
                    )}
                    <Box mt={2}>
                      <Button variant="outlined" sx={{ color: offer.statusColor }}>
                        {offer.status}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Slider>

        </Box>
      </Paper>
    </Box> */}

 <Box mt={4} display="flex" justifyContent="space-between" gap={3}>
      {/* Bar Chart Section (Finances v/s Settled) */}
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          background: 'linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)',
          border: '1px solid #F7F7F7',
          boxShadow: '10px 10px 40px 0px rgba(210, 210, 210, 0.6), -10px -10px 20px 0px #FFFFFF',
          width: '38%',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" style={{ color: '#404040', fontFamily: 'Noto Sans', fontSize: '18px' }}>
            Finances v/s Settled
          </Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={timePeriod}
              onChange={handleTimeChange}
              style={{
                color: '#80A0FF',
                border: '1px solid #80A0FF',
                fontFamily: 'Noto Sans',
                fontSize: '14px',
              }}
            >
              <MenuItem value="Last 7 days">Last 7 days</MenuItem>
              <MenuItem value="Last 30 days">Last 30 days</MenuItem>
              <MenuItem value="Last 6 months">Last 6 months</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Bar data={barChartData} options={chartOptions} />
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant="body2" sx={{ color: 'green', marginRight: 2 }}>
            ● Financed Amount
          </Typography>
          <Typography variant="body2" sx={{ color: 'blue' }}>
            ● Settled Invoices
          </Typography>
        </Box>
      </Paper>

      {/* Line Chart Section (Financed v/s Settled Analysis) */}
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          background: 'linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)',
          border: '1px solid #F7F7F7',
          boxShadow: '10px 10px 40px 0px rgba(210, 210, 210, 0.6), -10px -10px 20px 0px #FFFFFF',
          width: '58%',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" style={{ color: '#404040', fontFamily: 'Noto Sans', fontSize: '18px' }}>
            Financed v/s Settled Analysis
          </Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={yearPeriod}
              onChange={handleYearChange}
              style={{
                color: '#80A0FF',
                border: '1px solid #80A0FF',
                fontFamily: 'Noto Sans',
                fontSize: '14px',
              }}
            >
              <MenuItem value="Last Year">Last Year</MenuItem>
              <MenuItem value="Last 6 months">Last 6 months</MenuItem>
              <MenuItem value="Last 2 years">Last 2 years</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Line data={lineChartData} options={chartOptions} />
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant="body2" sx={{ color: 'green', marginRight: 2 }}>
            ● Financed Amount
          </Typography>
          <Typography variant="body2" sx={{ color: 'blue' }}>
            ● Settled Invoices
          </Typography>
        </Box>
      </Paper>
    </Box>

      {/* Recent Invoices */}
     <Box mt={4}>
      <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
       
        <Typography variant="h6" style={{color:"#404040"}}>Recent Invoices</Typography>
        <Box display="flex" alignItems="center">
            {/* Filter Buttons */}
            <Button
              variant={filter === "All" ? "contained" : "outlined"}
              onClick={() => setFilter("All")}
              sx={{ mx: 1 }}
            >
              ALL
            </Button>
            <Button
              variant={filter === "Retailer" ? "contained" : "outlined"}
              onClick={() => setFilter("Retailer")}
              sx={{ mx: 1 }}
            >
              RETAILER
            </Button>
            <Button
              variant={filter === "Supplier" ? "contained" : "outlined"}
              onClick={() => setFilter("Supplier")}
              sx={{ mx: 1 }}
            >
              SUPPLIER
            </Button>
            </Box>
            </Box>

        {/* Controls Section */}
        <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
          {/* Left Side: Dropdown and Apply Button */}
          <Box display="flex" alignItems="center">
          <FormControl size="small" sx={{ mr: 2, width: '100%' }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
    }}
  >
    <MenuItem value="" disabled>
      Download Invoices
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>

            <Button variant="contained" color="primary">
              APPLY
            </Button>
          </Box>

          {/* Right Side: Filter Buttons and Date Picker */}
          <Box display="flex" alignItems="center">
            {/* Filter Buttons */}
         

            {/* Date Range Picker */}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
               sx={{
                color: "#80A0FF",
                border: "1px solid #80A0FF",
                fontFamily: "Noto Sans",
                fontSize: "14px",
                '& .MuiSelect-select': {
                  color: "#80A0FF", // Set the text color for the selected item
                },
                '& .MuiInputBase-root': {
                  border: "1px solid #80A0FF", // Set the border color
                },
              }}
                startText="Start"
                endText="End"
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} size="small"   sx={{ mx: 1,
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
    }} />
                    <TextField {...endProps} size="small" sx={{ mx: 1,
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
    }}/>
                  </>
                )}
              />
            </LocalizationProvider> */}

<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DateRangePicker
    sx={{
      color: "#000", // Ensure the text color is black
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiInputBase-input': {
        color: "#000", // Set the input text color to black
      },
      backgroundColor:"#80A0FF",
      borderRadius: '8px'
    }}
    startText="Start"
    endText="End"
    value={dateRange}
    onChange={(newValue) => setDateRange(newValue)}
    renderInput={(startProps, endProps) => (
      <>
        <TextField
          {...startProps}
          size="small"
          InputProps={{
            style: { color: "#000" }, // Set label color to black
          }}
          sx={{
            mx: 1,
            fontFamily: "Noto Sans",
            fontSize: "14px",
            '& .MuiInputBase-input': {
              color: "#000", // Set the input text color to black
            },
          }}
        />
        <TextField
          {...endProps}
          size="small"
          InputProps={{
            style: { color: "#000" }, // Set label color to black
          }}
          sx={{
            mx: 1,
            fontFamily: "Noto Sans",
            fontSize: "14px",
            '& .MuiInputBase-input': {
              color: "#000", // Set the input text color to black
            },
            
          }}
        />
      </>
    )}
  />
</LocalizationProvider>

            {/* Filter By Button */}
            {/* <IconButton>
              <FilterList />
            </IconButton> */}
          </Box>
        </Box>

        {/* DataGrid Section */}
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            sx={{
              '& .MuiDataGrid-cell': {
                color: '#555555', // Set the text color for the cells
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFFFFF',
                backgroundColor:"#5873C5" // Set the text color for the column headers
              },
              '& .MuiCheckbox-root': {
                color: '#555555', // Set the default checkbox color
                '&.Mui-checked': {
                  color: '#555555', // Set the color when the checkbox is checked
                },
              },
            }}
          />
        </div>
      </Box>
    </Box>
    </Box>
  );
}
