import React, {useState} from 'react';
import { Box, Typography, Grid, Button,Icon, Paper, Avatar, Table, TableBody, TableRow, TableCell, Divider, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentCopy from '@mui/icons-material/ContentCopy'; // Import the copy icon

import OfferFinancingDialog from '../../components/OfferFinancingDialog';
import SettleInvoiceDialog from '../../components/SettleInvoiceDialog';
import { useNavigate } from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image'; // Default image icon
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import maggi from "assets/profile.jpeg";


const InvoiceCashFlowAnalysis = () => {

  // var maggi;

    const navigate = useNavigate(); // Move useNavigate to be accessible here

    const [open, setOpen] = useState(false);

    const [settleopen, settlesetOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const handleSettleClickOpen = () => {
        settlesetOpen(true);
      };
    
      const handleSettleClose = () => {
        settlesetOpen(false);
      };

      const retailerViewDetailsButton = () =>{
        navigate("retailerdetails");
      }

      const supplierViewDetailsButton = () =>{
        navigate("supplierdetails");
      }



  return (
    <Box sx={{ p: 4, bgcolor: '#F8F9FD' }}>
      {/* Header */}
      {/* <Box
  elevation={3}
  sx={{
    p: 3,
    mb: 3,
    background: "linear-gradient(102.56deg, #5275DE 0.41%, #80A0FF 100%)",
    borderRadius: '16px', // Optional: Add border radius for smoother edges
    color: '#fff', // Optional: Set text color to white for better contrast
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Ensures vertical alignment of text
  }}
>
  <Typography variant="h6" sx={{ fontSize:"20px", fontWeight: 'bold', color: '#fff' }}>
  <span style={{ fontSize: "30px", fontWeight: 'bolder' }}>%</span> Clearance Percentage
  </Typography>
  <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#fff' }}>
    100%
  </Typography>
</Box> */}


      {/* Payment Pending Section */}
      <Grid container spacing={3} alignItems="flex-start">
      {/* First Grid Item: Payment Details */}
      <Grid item xs={12} md={8}>
        <Box elevation={3} sx={{ p: 3, mb: 3, color: "#404040", background: "#FFFFFF", border: "1px Solid #EEEEEE", borderRadius: "16px" }}>
          <Typography variant="h6" fontWeight="bold" sx={{ color: "#404040", fontSize: "16px" }}>
            Transaction
          </Typography>

          {/* Invoice Value */}
          <Box sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "5px",
            paddingBottom: "9px",
            borderRadius: '16px',
            border: "1px Solid #F06979",
            backgroundColor: "#F9DEDE",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Transaction Amount:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              ₹1,00,000
            </Typography>
          </Box>

          {/* Invoice ID */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Transaction ID:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
            UJY397984796
            </Typography>
          </Box>

          {/* Payment Due Date */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Transaction Date:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              12 Sep 22
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Transaction Settlement Type:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              card
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Transaction Status:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Successful
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}>
            <Typography variant="h5" sx={{ fontWeight: '400', mt: 1, color: "#555555" }}>
              Transaction Location Match:
            </Typography>
            <Icon
  sx={{
    backgroundColor: "transaparent",
    marginRight: 3,
    marginTop: 1,
    borderRadius: "50%",
    width: 25,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <CheckCircleIcon sx={{ color: "green", fontSize: 30 }} />
</Icon>

          </Box>
        </Box>
      </Grid>

      {/* Second Grid Item: Image Display */}
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '16px',
            boxShadow: 3,
            padding: 2,
            height: '100%',
          }}
        >
          {maggi ? (
            <img
              src={maggi} // Path to your image
              alt="maggi"
              style={{
                width: '100%',  // Take full width of the Box
                height:"220px",
                objectFit: 'contain', // Maintain aspect ratio
              }}
            />
          ) : (
            <ImageIcon
              sx={{
                color: "gray",
                width: '100%',
                height:"220px",
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>



      {/* Supplier and Retailer Details */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
        <Box 
      sx={{ 
        color: "#000", 
        borderRadius: 3, 
        boxShadow: 3, 
        border: '2px solid #e0e0e0', 
        position: 'relative', 
        overflow: 'hidden'
      }}
    >
      <Box sx={{ backgroundColor: '#5873C5', padding: '12px 16px 12px 16px', borderRadius: '12px 12px 0 0', color: '#fff', display:"flex", justifyContent: 'space-between',
    alignItems: 'center'}}>
        <Typography variant="body1" fontWeight="bold">Transaction Originator</Typography>
        {/* <Button 
        onClick={supplierViewDetailsButton}
        sx={{ color: '#fff', fontSize: '10px', padding: "9px, 12px, 9px, 12px", background: "#FFFFFF", color: "#80A0FF"

        }}>VIEW DETAILS</Button> */}
        <Box></Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, padding: '12px 16px 12px 16px' }}>
        <Avatar sx={{ mr: 2 }} alt="Rohan Malhotra" src="/path-to-rohan-image.jpg" />
        <Box>
          <Typography sx={{color:"#555555", fontSize:"16px"}}>Rohan Malhotra</Typography>
          <Typography sx={{ background: "#80A0FF29",
      color: "#404040",
      fontSize: "14px",
      display: "inline-block", // Ensures the background fits the content
      padding: "2px 8px", // Optional: Adds padding for better appearance
      borderRadius: "12px", // Optional: Add border radius for smoother edges
      }}>BRXCSS87W</Typography>
        </Box>
      </Box>

      <Divider sx={{ my: 2, backgroundColor: '#EEEEEE' }} />

      <Table sx={{ minWidth: 650 }}>
  <TableBody>
    {/* Row for Location */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Location:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Varanasi
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Latitude, Longitude:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
      12.958391849915412, 77.6058756
      <IconButton
  onClick={() => window.open("https://www.google.com/maps/@12.9094306,77.5275351,15z?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D", "_blank")}
sx={{mb:1}}
>
  <LocationOnIcon sx={{ color: "green" }} />
</IconButton>
      </TableCell>
    </TableRow>

    {/* Row for Email Address */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Email Address:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Nov 8, 1985
      </TableCell>
    </TableRow>

    {/* Row for Direct Manager */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Direct Manager:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Spurthi
      </TableCell>
    </TableRow>

    {/* Row for Mobile Number */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Mobile Number:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        +91 7483736802
      </TableCell>
    </TableRow>
    
  </TableBody>
</Table>

<Divider sx={{ my: 2, backgroundColor: '#EEEEEE' }} />

{/* <Box sx={{margin: "20px"}}>
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        onClick={handleSettleClickOpen}
        sx={{ borderRadius:"16px", backgroundColor: '#0E1559', padding: "12px, 24px, 12px, 24px", fontSize:"20px", fontFamily:"Montserrat", fontWeight:"700", letterSpacing:"0.02em" }}
      >
        SETTLE INVOICE
      </Button>
      <SettleInvoiceDialog open={settleopen} handleClose={handleSettleClose} />
      </Box> */}
    </Box>
        </Grid>

        <Grid item xs={12} sm={6}>


        <Box 
      sx={{ 
        color: "#000", 
        borderRadius: 3, 
        boxShadow: 3, 
        border: '2px solid #e0e0e0', 
        position: 'relative', 
        overflow: 'hidden'
      }}
    >
      <Box sx={{ backgroundColor: '#5873C5', padding: '12px 16px 12px 16px', borderRadius: '12px 12px 0 0', color: '#fff', display:"flex", justifyContent: 'space-between',
    alignItems: 'center'}}>
        <Typography variant="body1" fontWeight="bold">Transaction Beneficiary</Typography>
        <Box></Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, padding: '12px 16px 12px 16px' }}>
        <Avatar sx={{ mr: 2 }} alt="Rohan Malhotra" src="/path-to-rohan-image.jpg" />
        <Box>
          <Typography sx={{color:"#555555", fontSize:"16px"}}>Spurthi XYZ</Typography>
          <Typography sx={{ background: "#80A0FF29",
      color: "#404040",
      fontSize: "14px",
      display: "inline-block", // Ensures the background fits the content
      padding: "2px 8px", // Optional: Adds padding for better appearance
      borderRadius: "12px", // Optional: Add border radius for smoother edges
      }}>BRXCSS87W</Typography>
        </Box>
      </Box>

      <Divider sx={{ my: 2, backgroundColor: '#EEEEEE' }} />

      <Table sx={{ minWidth: 650 }}>
  <TableBody>
    {/* Row for Location */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Location:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Varanasi
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Latitude, Longitude:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
      12.958391849915412, 77.6058756
      <IconButton
  onClick={() => window.open("https://www.google.com/maps/@12.9094306,77.5275351,15z?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D", "_blank")}
sx={{mb:1}}
>
  <LocationOnIcon sx={{ color: "green" }} />
</IconButton>

      </TableCell>
    </TableRow>

    {/* Row for Email Address */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Email Address:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
      suresh@example.com
      </TableCell>
    </TableRow>

    {/* Row for Direct Manager */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Direct Manager:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
      suresh  
      </TableCell>
    </TableRow>

    {/* Row for Mobile Number */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Mobile Number:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
      +91 7489576218
      </TableCell>
    </TableRow>
  </TableBody>
</Table>

<Divider sx={{ my: 2, backgroundColor: '#EEEEEE' }} />

{/* <Box sx={{margin: "20px"}}>
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        sx={{ borderRadius:"16px", backgroundColor: '#0E1559', padding: "12px, 24px, 12px, 24px", fontSize:"20px", fontFamily:"Montserrat", fontWeight:"700", letterSpacing:"0.02em" }}
        onClick={handleClickOpen}
      >
        Offer Financing
      </Button>
      <OfferFinancingDialog open={open} handleClose={handleClose} />
      </Box> */}
    </Box>
        </Grid>
      </Grid>

      {/* Order Details */}
      <Box
      sx={{
        mb: 3,
        backgroundColor: 'white',
        borderRadius: "16px",
        boxShadow: 3,
        border: '1px solid #e0e0e0',
        color: 'black',
      }}
    >
      <Box
        sx={{
        
          backgroundColor: '#4267b2',
          borderRadius: "16px 16px 0 0",
          paddingX: '32px',
          paddingY:"16px",
          color: 'white',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Order Details
        </Typography>
      </Box>

<Box sx={{padding:"20px"}}>
      <Table sx={{ minWidth: 650}}>
  <TableBody>
    {/* Row for Location */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",  borderBottom:"1px solid #EEEEEE"  }}>
      Order ID:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      BR-87627
      </TableCell>
    </TableRow>

    {/* Row for Email Address */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      Order Value:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      ₹46,000
      </TableCell>
    </TableRow>

    {/* Row for Direct Manager */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      SKUs:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      5
      </TableCell>
    </TableRow>

    {/* Row for Mobile Number */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      Ordered on:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      16 Nov 22
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      Order Status:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      <span style={{ color: 'green' }}>Delivered</span>
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"none",   }}>
      BR ID:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"none", textAlign:"right" }}>
      12345
      <IconButton
    sx={{ marginLeft: 1, color: "#555555" }} // Adjust spacing and color as needed
    onClick={() => navigator.clipboard.writeText('12345')} // Function to copy the text
  >
    <ContentCopy fontSize="small" sx={{color:"#F78200"}} />
  </IconButton>
      </TableCell>
    </TableRow>


  </TableBody>
</Table>
</Box>

    </Box>


{/* Inventory Purchased */}
    <Box
      sx={{
        mb: 3,
        backgroundColor: 'white',
        borderRadius: "16px",
        boxShadow: 3,
        border: '1px solid #e0e0e0',
        color: 'black',
      }}
    >
      <Box
        sx={{
        
          backgroundColor: '#4267b2',
          borderRadius: "16px 16px 0 0",
          paddingX: '32px',
          paddingY:"16px",
          color: 'white',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
        Inventory Purchased
        </Typography>
      </Box>

<Box sx={{padding:"20px"}}>
      <Table sx={{ minWidth: 650}}>
  <TableBody>
    {/* Row for Location */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",  borderBottom:"1px solid #EEEEEE"  }}>
      Maggi Masala Noodles 32g
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      4 Cartons
      </TableCell>
    </TableRow>

    {/* Row for Email Address */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      Lays Magic Masala 73g
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      5 Cartons
      </TableCell>
    </TableRow>

    {/* Row for Direct Manager */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      MTR Masala - Puliogare Powder, 27 g Pouch
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      5 Cartons
      </TableCell>
    </TableRow>

    {/* Row for Mobile Number */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      Lays Magic Masala 45g
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      5 Cartons
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE" }}>
      MTR Masala - Puliogare Powder, 27 g Pouch x 6Cations
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", textAlign:"right" }}>
      5 Cartons
      </TableCell>
    </TableRow>


  </TableBody>
</Table>
</Box>

    </Box>
    </Box>
  );
};

export default InvoiceCashFlowAnalysis;
