import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TablePinEntryDialog = ({ open, onPinVerified, handleClose }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleSubmit = () => {
    if (pin === '123456') {
      setError('');
      setPin('');
      onPinVerified(true);  // Call the parent with true if PIN is correct
      handleClose(); // Close the dialog if PIN is correct
    } else {
      setError('Invalid PIN. Please try again.');
      onPinVerified(false);  // Call the parent with false if PIN is incorrect
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle
        sx={{
          borderRadius: "16px 16px 0 0",
          background: "#FFFFFF",
          color: "#030747",
          fontFamily: "Noto Sans",
          fontSize: "21px",
          fontWeight: "500",
          lineHeight: "33.6px",
          textAlign: "left"
        }}
      >
        Enter PIN
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#FFFFFF",
            background: "#686A91",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#F7F7F7" }}>
        <Typography sx={{ color: "#404040", mb: 2 }}>
          Please enter your 6-digit PIN to continue.
        </Typography>

        <TextField
          label="Enter PIN"
          type="password"
          fullWidth
          value={pin}
          onChange={handlePinChange}
          inputProps={{
            maxLength: 6,
            sx: {
              color: "#000000",
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#404040',
              },
              '&:hover fieldset': {
                borderColor: '#404040',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#404040',
              },
            },
          }}
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions sx={{ pb: 2, background: "#F7F7F7" }}>
        <Button
          onClick={handleSubmit}
          style={{
            width: '100%',
            borderRadius: '12px',
            padding: '6px 23px 6px 20px',
            background: '#1C0E59',
            color: "#FFFFFF",
            fontSize: "18px"
          }}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TablePinEntryDialog;
