import React, {useState} from 'react';
import { Grid, TextField, Button, Checkbox, Typography, Link, Box } from '@mui/material';
import amanafin_logo from '../../assets/compliance.png';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS



const LoginPage = () => {

  const navigate = useNavigate();

    // States to store email and password input values
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

  const handleSignInClick = () => {
    // navigate('/invoiceanalysis');
    console.log(email);
    console.log(password);
    if (email.toLowerCase() === 'admin@branchx.in' && password === 'Admin@123') {
      navigate('/invoiceanalysis');
    } else {
      // Show toast message if login fails
      toast.error('Invalid email or password. Please try again.', {
        position: "top-center", // Use correct toast positioning
        autoClose: 3000, // Auto close after 3 seconds
      });
    }
  };

  return (
    <Grid container style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      {/* Right Side with login form */}
      <Grid
        item
        xs={12}
        md={5}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ padding: '0 40px' }}
      >
        {/* Logo at the top */}
        <Box textAlign="center" mb={4}>
          <img
            src={amanafin_logo}
            alt="Amanafin Logo"
            style={{ width: '205px', height: '48px' }}
          />
        </Box>



        {/* Sign In Form */}
        <Box
          sx={{
            width: '414px',
            padding: '32px',
            borderRadius: '24px',
            border: '1px solid #EEEEEE',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >

                  {/* Sign In text */}
        <Box mt={2} textAlign="center">
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: '48px',
              letterSpacing: '0.02em',
              textAlign: 'center',
              color: '#060606',
              marginBottom: '16px', // Add margin for spacing
            }}
          >
            SIGN IN
          </Typography>
        </Box>


          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.02em',
              textAlign: 'left',
              color: '#000000',
              fontWeight: 400,
            }}
          >
            Email
          </Typography>

          <TextField
            fullWidth
            margin="normal"
            type="email"
            variant="outlined"
            placeholder="Enter your work email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state
            InputProps={{
              style: {
                backgroundColor: '#F7F7F7',
                fontFamily: "Montserrat",
                fontSize: '16px',
                fontWeight: 400,
                color: "#555555",
                border: "1px solid #EEEEEE",
                borderRadius: "16px",
              },
            }}
          />

          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.02em',
              textAlign: 'left',
              color: '#000000',
              fontWeight: 400,
            }}
          >
            Password
          </Typography>

          <TextField
            fullWidth
            margin="normal"
            type="password"
            variant="outlined"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state
            InputProps={{
              style: {
                backgroundColor: '#F7F7F7',
                fontFamily: "Montserrat",
                fontSize: '16px',
                fontWeight: 400,
                color: "#555555",
                border: "1px solid #EEEEEE",
                borderRadius: "16px",
              },
            }}
          />

          <Box display="flex" alignItems="center">
            <Checkbox
              sx={{
                width: '16px',
                height: '16px',
                padding: 0,
                border: '3px solid #EEEEEE',
                borderRadius: '2px',
                marginRight: '10px'
              }}
            />
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.02em',
                textAlign: 'left',
                color: '#000000',
                fontWeight: 400,
              }}
            >
              Remember Me
            </Typography>
          </Box>

          <Button
            onClick={handleSignInClick}
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            sx={{
              marginTop: 2,
              backgroundColor: '#0f0f55',
              height: '50px',
              borderRadius: '8px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            SIGN IN
          </Button>

          <Box display="flex" justifyContent="center" alignSelf="center" mt={2}>
            <Link
              href="#"
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.02em',
                color: '#555555',
                fontWeight: 400,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Forgot Password?
            </Link>
          </Box>
        </Box>

        {/* <Box mt={2} textAlign="center">
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '30px',
              letterSpacing: '0.02em',
              color: '#777777'
            }}
          >
            New to TenX?{' '}
            <Link
              href="signup"
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '20px',
                fontWeight: 600,
                color: '#80A0FF',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Register Now
            </Link>
          </Typography>
        </Box> */}
      </Grid>

      <ToastContainer />
    </Grid>
  );
};

export default LoginPage;
