import React, {useState} from 'react';
import {TextField, FormControl,InputAdornment, Select, MenuItem,  Box, Divider,Icon, Container, Grid, Card, CardContent, IconButton, TableColumn, Typography, Avatar, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, colors } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Label } from 'recharts';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ImageIcon from '@mui/icons-material/Image';
import GaugeChart from 'react-gauge-chart';
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import SearchIcon from '@mui/icons-material/Search';
import { Visibility, FilterList } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';


const renderProfileSection = () =>(
    <Grid container spacing={2}>
      {/* Left Card */}
      <Grid item xs={12} sm={6}>

        <TableContainer sx={{background:"#FFFFFF", borderRadius:"12px"}}>
      <Table sx={{border:'none', fontSize:"14px"}}>
        <TableBody>
          <TableRow>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="h6" sx={{color:"#777777"}}>Location:</Typography>
            </TableCell>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="body2" sx={{color:"#404040", textAlign:"right"}}>Varanasi, Uttar Pradesh</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="h6" sx={{color:"#777777"}}>Email Address:</Typography>
            </TableCell>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="body2" sx={{color:"#404040", textAlign:"right"}}>RohBusiness12@gmail.com</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="h6" sx={{color:"#777777"}}>Direct Manager:</Typography>
            </TableCell>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="body2" sx={{color:"#404040", textAlign:"right"}}>Mr. Spurthi</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="h6" sx={{color:"#777777"}}>Mobile Number:</Typography>
            </TableCell>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="body2" sx={{color:"#404040", textAlign:"right"}}>+91 7483736802</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="h6" sx={{color:"#777777"}}>Alternate Contact:</Typography>
            </TableCell>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="body2" sx={{color:"#404040", textAlign:"right"}}>+91 8483736001</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="h6" sx={{color:"#777777"}}>Working Since:</Typography>
            </TableCell>
            <TableCell  sx={{borderBottom:'none'}}>
              <Typography variant="body2" sx={{color:"#404040", textAlign:"right"}}>January 2012</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

      </Grid>

      {/* Customer Retention Card */}
      <Grid item xs={12} sm={6}>
        <Card variant="outlined" sx={{background:"#FFFFFF", mb:2, px:2, border:"none", borderRadius:"12px"}}>
  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    {/* Textual content */}
    <Box>
      <Typography variant="h6" sx={{ color: "#404040", fontSize: "14px", mb: 1 }}>
        Customer Retention Rate
      </Typography>
      <Typography variant="h4" color="primary" gutterBottom sx={{ color: "#5873C5", fontSize: "24px" }}>
        31.5%
      </Typography>
      <Typography variant="body2" sx={{ color: "#555555", fontSize: "14px" }}>
        90-Days Customer
      </Typography>
      <Typography variant="body2" sx={{ color: "#555555", fontSize: "14px" }}>
        Retention Rate
      </Typography>
    </Box>

    {/* Chart */}
    <Box style={{ backgroundColor: 'white', padding: '20px' }}>
      <ResponsiveContainer width={300} height={100}>
        <LineChart data={data}>
          {/* Remove the grid and axis */}
          <Line
            type="monotone"
            dataKey="value"
            stroke="#1976d2"
            strokeWidth={2}
            strokeDasharray="5 5" // Dashed line
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  </Box>

        </Card>

      {/* Rate of Return Card */}

        <Card variant="outlined" sx={{borderRadius:"12px", border:"none"}}>
         
            <Box sx={{display:"flex", justifyContent:"space-between", background:"#FFFFFF", px:2}}>
            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-around", color:"#404040"}}>
            <Typography variant="h6">Rate of Return</Typography>
            <Box>
            <Typography variant="h4" sx={{color:"#5873C5"}}>
              26.1%
            </Typography>
            <Typography variant="body2">Rate Of Return</Typography>
            </Box>
            </Box>
            <Box mt={2}>
              <GaugeChart
                id="gauge-chart1"
                nrOfLevels={30} // Higher number for smoother transitions
                arcsLength={[0.26, 0.74]} // 26% green, rest white
                percent={0.26}
                colors={['#758AC4', '#DDDDDD']} // Green up to 26%, then white
                arcPadding={0} // Padding between the color segments
                border={0}
              />
            </Box>
            </Box>
        </Card>
    </Grid>
    </Grid>
);

const renderKYCSection = () => (
    <TableContainer sx={{borderRadius:"16px"}}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow sx={{background:"#5873C5", color:"#FFFFFF"}}>
          <TableCell align="left" sx={{width: '10%'}}>SI No.</TableCell>
          <TableCell align="left" sx={{width: '30%'}}>Document Type</TableCell>
          <TableCell align="left" sx={{width: '20%'}}>Side</TableCell>
          <TableCell align="left" sx={{width: '25%'}}>File</TableCell>
          <TableCell align="left" sx={{width: '15%'}}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
          >
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>
              {row.name}
            </TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>{row.calories}</TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>{row.fat}</TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>
              <Icon>
                  <ImageIcon />
              </Icon>
            </TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>{row.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);


const renderShopAddressSection = () => (
    <TableContainer sx={{borderRadius:"16px"}}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow sx={{background:"#5873C5", color:"#FFFFFF"}}>
          <TableCell align="left" sx={{width: '10%'}}>SI No.</TableCell>
          <TableCell align="left" sx={{width: '20%'}}>Shop No.</TableCell>
          <TableCell align="left" sx={{width: '40%'}}>Address</TableCell>
          <TableCell align="left" sx={{width: '15%'}}>Pincode</TableCell>
          <TableCell align="left" sx={{width: '15%'}}>Functional Year</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
          >
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>
              {row.name}
            </TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>{row.calories}</TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>{row.fat}</TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>
              <Icon>
                  <ImageIcon />
              </Icon>
            </TableCell>
            <TableCell sx={{ color:"#404040", borderBottom: "1px solid #EEEEEE" }}>{row.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);


const renderShopPerformanceSection = () => (
    <Box sx={{ p: 3, borderRadius: '16px', background: "linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)", border: "1px solid #F7F7F7", boxShadow: "10px 10px 40px 0px #D2D2D299, -10px -10px 20px 0px #FFFFFF" }}>
      <Typography variant="h6" align="left" sx={{color:"#404040", fontSize:"18px", mb:2}}>
        Monthly Performance
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={shopdata} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month">
          <Label value="Duration →" offset={-10} position="insideBottomLeft" sx={{ color:"#404040"}} />
          </XAxis>
          <YAxis />
          <Tooltip />
          <Legend align="right" verticalAlign="bottom" />
          <Line type="monotone" dataKey="shop1" stroke="#82ca9d" strokeWidth={2} />
          <Line type="monotone" dataKey="shop2" stroke="#8884d8" strokeWidth={2} />
          <Line type="monotone" dataKey="shop3" stroke="#ffc658" strokeWidth={2} />
          <Line type="monotone" dataKey="shop4" stroke="#413ea0" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>

);

const createData = (name, calories, fat, protein) => {
    return { name, calories, fat, protein };
  };

  const data = [
    { name: 'Point 1', value: 20 },
    { name: 'Point 2', value: 30 },
    { name: 'Point 3', value: 25 },
    { name: 'Point 4', value: 35 },
    { name: 'Point 5', value: 31.5 },
  ];
  
  const rows = [
    createData(1, 'Aadhaar', 'Front', 'Not Submitted'),
    createData(2, 'Aadhaar', 'Back', 'Submitted'),
    createData(3, 'PAN', 'Front', 'Submitted'),
    createData(4, 'Cancelled Check', 'Front', 'Not Submitted'),
  ];

const mockData = {
  profile: {
    name: 'Kunal Sehgal',
    storeId: 'BRKCS776W',
    metrics: [
      { label: 'Revenue Generated', value: '₹1,725,548' },
      { label: 'Customers Acquired', value: '12457' },
      { label: 'Inventory Purchased', value: '₹725,548' },
      { label: 'Transactions Completed', value: '1,725' },
      { label: 'Gross Profit Generated', value: '₹1,00,548' },
      { label: 'Purchase Order Completed', value: '24515' },
    ],
    creditScore: 726,
    location: 'Thane',
    dob: 'Aug 14, 1988',
    designation: 'Sprint',
    mobileNumber: '+91 1234567890',
  },
  kyc: [
    { type: 'Aadhaar', side: 'Front', status: 'Not Found', action: 'Not Submitted' },
    { type: 'Aadhaar', side: 'Back', status: 'Aadhaar Card Back', action: 'Submitted' },
    { type: 'PAN', side: 'Front', status: 'PAN Card Front', action: 'Submitted' },
    { type: 'Canceled Check', side: 'Front', status: 'Not Found', action: 'Not Submitted' },
  ],
  salesData: [
    { month: 'Jan', sales: 300 },
    { month: 'Feb', sales: 500 },
    { month: 'Mar', sales: 200 },
    { month: 'Apr', sales: 278 },
    { month: 'May', sales: 390 },
    { month: 'Jun', sales: 450 },
  ],
  creditHistory: [
    { name: 'Ashok Lathar', location: 'Amritsar', amount: '₹7.5 Lakh' },
    { name: 'Madhan Tane', location: 'Thane', amount: '₹4.5 Lakh' },
    { name: 'Farsi Tyagi', location: 'Puducherry', amount: '₹3.1 Lakh' },
  ],
  transactionData: [
    { month: 'Jan', UPI: 500, Cash: 200, POS: 150, Credit: 100 },
    { month: 'Feb', UPI: 600, Cash: 300, POS: 200, Credit: 120 },
  ],
};

  const columns = [
    { field: 'name', headerName: 'Name', flex:1},
    { field: 'location', headerName: 'Location', flex:1},
    { field: 'amount', headerName: 'Amount', flex:1 },
  ];

  const mockDataColumn = {
    creditHistory: [
      { id: 1, name: 'Ashok Lathar', location: 'Amritsar', amount: '₹7.5 Lakh' },
      { id: 2, name: 'Madhan Tane', location: 'Thane', amount: '₹4.5 Lakh' },
      { id: 3, name: 'Farsi Tyagi', location: 'Puducherry', amount: '₹3.1 Lakh' },
    ],
  };

  const shopdata = [
    { month: 'Jan', shop1: 10000, shop2: 24000, shop3: 14000, shop4: 28000 },
    { month: 'Feb', shop1: 12000, shop2: 22000, shop3: 16000, shop4: 26000 },
    { month: 'Mar', shop1: 18000, shop2: 20000, shop3: 8000, shop4: 30000 },
    { month: 'Apr', shop1: 15000, shop2: 28000, shop3: 5000, shop4: 32000 },
    { month: 'May', shop1: 20000, shop2: 30000, shop3: 10000, shop4: 34000 },
    { month: 'Jun', shop1: 18000, shop2: 26000, shop3: 12000, shop4: 29000 },
    { month: 'Jul', shop1: 22000, shop2: 27000, shop3: 15000, shop4: 33000 },
    { month: 'Aug', shop1: 17000, shop2: 28000, shop3: 16000, shop4: 30000 },
    { month: 'Sep', shop1: 25000, shop2: 24000, shop3: 14000, shop4: 31000 },
    { month: 'Oct', shop1: 23000, shop2: 22000, shop3: 13000, shop4: 27000 },
    { month: 'Nov', shop1: 22000, shop2: 25000, shop3: 12000, shop4: 32000 },
    { month: 'Dec', shop1: 24000, shop2: 29000, shop3: 18000, shop4: 35000 },
  ];




  const retailercolumns = [
    { field: "suppliername", headerName: "Supplier Name",flex: 1},
    { field: "invoiceid", headerName: "Invoice ID", flex: 1 },
    { field: "status", headerName: "Status", flex: 1,  renderCell: (params) => (
        <span
          style={{
            color: params.value === "paid" ? "green" : "red",
          }}
        >
          {params.value}
        </span>
      ), },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "clearance", headerName: "Clearance %", flex: 1},
    {
      field: "orderStatus",
      headerName: "Order Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === "Delivered" ? "green" : "red",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: () => (
        <IconButton>
          <Visibility style={{color:"#555555"}}/>
        </IconButton>
      ),
    }
  ];
  
  const retailerrows = [
    {
      id: 1,
      suppliername: "#3245",
      invoiceid: "Arya Singh",
      status: "Mommin Mohi",
      amount: "12.01.2024",
      clearance: "12.01.2024",
      orderStatus: "Delivered",
    },
    {
      id: 2,
      suppliername: "#3245",
      invoiceid: "Arya Singh",
      status: "Mommin Mohi",
      amount: "12.01.2024",
      clearance: "12.01.2024",
      orderStatus: "Delivered",
    },
    // Add more rows here based on your data
  ];

const RetailerDetails = () => {

    const [activeSection, setActiveSection] = useState('Profile');
    const [dateRange, setDateRange] = React.useState([null, null]);

    const navigate = useNavigate(); // Move useNavigate to be accessible here


    const inventorycolumns = [
        { field: "productname", headerName: "Product Name",flex: 1},
        { field: "category", headerName: "Category", flex: 1 },
        { field: "brand", headerName: "Brand", flex: 1 },
        { field: "distributor", headerName: "Distributor", flex: 1 },
        { field: "sku", headerName: "SKU", flex: 1},
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: (params) => (
            <span
              style={{
                color: params.value === "Delivered" ? "green" : "red",
              }}
            >
              {params.value}
            </span>
          ),
        },
        { field: "unitprice", headerName: "Unit Price", flex: 1 },
        { field: "unitsold", headerName: "Units Sold", flex: 1 },
        { field: "dailysales", headerName: "Daily Sales", flex: 1 },
        { field: "totalsales", headerName: "Total Sales", flex: 1 },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          renderCell: () => {
            const handleClick = () =>{
                navigate("inventorydetails");
              }
            
            return (
            <IconButton onClick={handleClick}>
              <Visibility style={{color:"#555555"}}/>
            </IconButton>
          );
        },
        }
      ];
      
      const inventoryrows = [
        {
          id: 1,
          productname: "#3245",
          category: "Arya Singh",
          brand: "Mommin Mohi",
          distributor: "12.01.2024",
          sku: "12.01.2024",
          status: "₹2,558",
          unitprice: "Delivered",
          unitsold: "Delivered",
          dailysales: "Delivered",
          totalsales: "Delivered",
        },
        {
          id: 2,
          productname: "#3245",
          category: "Arya Singh",
          brand: "Mommin Mohi",
          distributor: "12.01.2024",
          sku: "12.01.2024",
          status: "₹2,558",
          unitprice: "Delivered",
          unitsold: "Delivered",
          dailysales: "Delivered",
          totalsales: "Delivered",
        },
        // Add more rows here based on your data
      ];


  return (
    <Container>
      {/* Retailer Profile Header */}
      <Grid container spacing={2} marginTop={4}>
  {/* Metrics Section */}
  <Grid item xs={12} md={7}>
    <Grid container spacing={2}>
      {mockData.profile.metrics.map((metric, index) => (
        <Grid item xs={12} sm={4} key={index}>
         <Card sx={{background: "linear-gradient(275.21deg, #5D81E9 0%, #3154BC 100%)", borderRadius:"16px", height:"140px"}}>
  <CardContent sx={{display:"flex", justifyContent:"center", alignItems:"center", height: "100%"}}>
    <Box>
      <Typography variant="h6" sx={{fontFamily: "Noto Sans", fontWeight:"600", fontSize:"16px", lineHeight:"21.79px", letterSpacing:"0.02em"}}>{metric.label}</Typography>
      <Typography variant="h5" sx={{fontFamily: "Noto Sans", fontWeight:"700", fontSize:"24px", lineHeight:"32.69px", letterSpacing:"0.02em"}}>{metric.value}</Typography>
    </Box>
  </CardContent>
</Card>

        </Grid>
      ))}
    </Grid>
  </Grid>

  {/* Retailer Details Section */}
  <Grid item xs={12} md={5}>
                    <Box 
      sx={{ 
        color: "#000", 
        borderRadius: 3, 
        boxShadow: 3, 
        border: '2px solid #e0e0e0', 
        position: 'relative', 
        overflow: 'hidden'
      }}
    >
      <Box sx={{ backgroundColor: '#5873C5', padding: '12px 16px 12px 16px', borderRadius: '12px 12px 0 0', color: '#fff'}}>
        <Typography variant="body1" fontWeight="bold">Retailer Details:</Typography>
         </Box>

         <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
       
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, padding: '12px 16px 12px 16px' }}>
        <Avatar sx={{ mr: 2, width:"60px", height:"60px" }} alt="Rohan Malhotra" src="/path-to-rohan-image.jpg" />
        <Box>
          <Typography sx={{color:"#555555", fontSize:"16px"}}>Rohan Malhotra</Typography>
          <Typography sx={{ background: "#80A0FF29",
      color: "#404040",
      fontSize: "14px",
      display: "inline-block", // Ensures the background fits the content
      padding: "2px 8px", // Optional: Adds padding for better appearance
      borderRadius: "12px", // Optional: Add border radius for smoother edges
      }}>BRXCSS87W</Typography>
        </Box>
      </Box>

      <Box>
    <Typography sx={{color:"#5873C5",mt:1, mb:1}}>Credit Score:</Typography>
    <Box sx={{
        fontFamily:"Noto Sans",
        fontWeight:"600",
        fontSize:"14px",
        lineHeight:"19.07px",
        display:"flex", 
        justifyContent:"center",
         alignItems:"center", 
         background:"#4DB9201F",
        color:"#4DB920",
        padding:"20px, 16px, 8px, 16px",
        width:"77px",
        height:"35px",
        borderRadius:"8px",
        mr:5}}>
        <Typography >726</Typography>
        <Icon>
            <ThumbUpIcon />
        </Icon>
    </Box>
      </Box>

      </Box>

      <Divider sx={{ my: 2, backgroundColor: '#EEEEEE' }} />

      <Table sx={{ minWidth: 450 }}>
  <TableBody>
    {/* Row for Location */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none', }}>
        Location:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none', textAlign:"right"}}>
        Varanasi
      </TableCell>
    </TableRow>

    {/* Row for Email Address */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Email Address:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none',  textAlign:"right"}}>
        Nov 8, 1985
      </TableCell>
    </TableRow>

    {/* Row for Direct Manager */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Direct Manager:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none',  textAlign:"right"}}>
        Spurthi
      </TableCell>
    </TableRow>

    {/* Row for Mobile Number */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none' }}>
        Mobile Number:
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "2px 16px",  borderBottom: 'none',  textAlign:"right" }}>
        +91 7483736802
      </TableCell>
    </TableRow>
  </TableBody>
</Table>

    </Box>
  </Grid>

  <Grid item xs={12} md={12} mb={3}>
    <Grid container>
      
        <Grid item xs={12} sm={3}>
         <Button onClick={() => setActiveSection('Profile')} fullWidth sx={{borderRadius:"12px 0px 0px 12px", background: activeSection === 'Profile' ? "#0E1559" : "#FFFFFF", color: activeSection === 'Profile' ? "#FFFFFF" : "#0E1559",border: "1px solid #80A0FF"}}>Profile</Button>
        </Grid>
        <Grid item xs={12} sm={3}>
         <Button onClick={() => setActiveSection('KYC')} fullWidth sx={{borderRadius:"0px", background: activeSection === 'KYC' ? "#0E1559" : "#FFFFFF", color: activeSection === 'KYC' ? "#FFFFFF" : "#0E1559",border: "1px solid #80A0FF"}}>KYC</Button>
        </Grid>
        <Grid item xs={12} sm={3}>
         <Button onClick={() => setActiveSection('ShopAddress')} fullWidth sx={{borderRadius:"0px", background: activeSection === 'ShopAddress' ? "#0E1559" : "#FFFFFF", color: activeSection === 'ShopAddress' ? "#FFFFFF" : "#0E1559",border: "1px solid #80A0FF"}}>Shop Address</Button>
        </Grid>
        <Grid item xs={12} sm={3}>
         <Button onClick={() => setActiveSection('ShopPerformance')} fullWidth sx={{borderRadius:"0px 12px 12px 0px", background: activeSection === 'ShopPerformance' ? "#0E1559" : "#FFFFFF", color: activeSection === 'ShopPerformance' ? "#FFFFFF" : "#0E1559",border: "1px solid #80A0FF"}}>Shop Performance</Button>
        </Grid>
    </Grid>
  </Grid>
</Grid>


        {/* Render the active section based on the state */}
      {activeSection === 'Profile' && renderProfileSection()}
      {activeSection === 'KYC' && renderKYCSection()}
      {activeSection === 'ShopAddress' && renderShopAddressSection()}
      {activeSection === 'ShopPerformance' && renderShopPerformanceSection()}




      <Grid container spacing={2} style={{ marginTop: '20px' }}>
  {/* Business Sales */}
  <Grid item xs={12} md={8}>
    <Box sx={{display:"flex", justifyContent:"space-between"}}>
  <Typography variant="h6" sx={{color:"#404040", mb:2}}>Business Sales</Typography>
  <Box display="flex">
  <Box display="flex" alignItems="center">
          <FormControl size="small" sx={{ mr: 2, width: '100%' }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
      borderRadius:"16px"
    }}
  >
    <MenuItem value="" disabled>
      Sales
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>

          </Box>
          <Box display="flex" alignItems="center">
          <FormControl size="small" sx={{ mr: 2, width: '100%' }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
      borderRadius:"16px"
    }}
  >
    <MenuItem value="" disabled>
      Last Year
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>
          </Box>
          </Box>
  </Box>



    <Card sx={{mt:1}}>
      <CardContent sx={{background:"#FFFFFF"}}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={mockData.salesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
    
  </Grid>

  {/* Credit History */}
  <Grid item xs={12} md={4} mt={1}>
  <Container>
      <Typography variant="h6" sx={{ marginBottom: 2, color:"#404040" }}>
        Credit History
      </Typography>

      <Box style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={mockDataColumn.creditHistory}
          columns={columns}
          autoHeight
        //   hideFooterPagination
          hideFooter
          sx={{
            border: '1px solid #EEEEEE', // Set the border color
            borderRadius: '12px', // Set the border radius
            '& .MuiDataGrid-cell': {
                color: '#555555', // Set the text color for the cells
            },
            '& .MuiDataGrid-columnHeader': {
                color: '#FFFFFF',
                backgroundColor: "#5873C5" // Set the background color for the column headers
            },
            '& .MuiCheckbox-root': {
                color: '#555555', // Set the default checkbox color
                '&.Mui-checked': {
                    color: '#555555', // Set the color when the checkbox is checked
                },
            },
            '& .MuiPaginationItem-root': {
                color: '#555555', // Set the color for pagination items
                '&.Mui-selected': {
                    backgroundColor: '#5873C5', // Set the background color for the selected page
                    color: '#FFFFFF', // Set the text color for the selected page
                },
            },
            '& .MuiTablePagination-toolbar': {
                color: '#555555', // Set the color for the pagination toolbar
            }
        }}
        />
      </Box>
    </Container>
  </Grid>
</Grid>


      {/* Transaction Data */}
      <Grid container spacing={2}>
      <Grid item xs={12} md={12} >
    <Box sx={{display:"flex", justifyContent:"space-between"}} mb={2}>
      <Typography variant="h6" sx={{color:"#404040", fontSize:"18px"}}>Transaction Data</Typography>
      <Box sx={{display:"flex"}}>
        <Button sx={{mr:2, borderRadius:"8px", padding:"6px, 12px, 8px, 12px", color:"#FFFFFF", background:"#80A0FF", border:"1px solid #80A0FF"}}>Weekly</Button>
        <Button sx={{mr:2, borderRadius:"8px", padding:"6px, 12px, 8px, 12px", color:"#80A0FF", background:"#FFFFFF", border:"1px solid #80A0FF"}}>Monthly</Button>
      </Box>
      </Box>
      <Card style={{ marginBottom: '40px', background: "linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)"}}>
        <CardContent>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={mockData.transactionData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" >
              Month 
                </XAxis>
              <YAxis >Value</YAxis>
              <Tooltip />
              <Legend />
              <Bar dataKey="UPI" fill="#8884d8" />
              <Bar dataKey="Cash" fill="#82ca9d" />
              <Bar dataKey="POS" fill="#ffc658" />
              <Bar dataKey="Credit" fill="#ff7300" />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
      </Grid>
      </Grid>





    {/* List of retailers */}
      <Box sx={{ height: 600, width: "100%", backgroundColor: "transparent", p: 2, mb:2 }}>
      <Box marginBottom={2} sx={{width:"100%"}}>
  <TextField
    placeholder="Search by retailer or supplier name or invoice value"
    variant="outlined"
    size="small"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{color:"#999999"}} />
        </InputAdornment>
      ),
      style: {
        width: '100%',  // Full width
        padding: "8px 0px 8px 16px",
        gap: "8px",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",
        opacity: 1,
        color:"#999999"  // Ensure visibility
      },
    }}
  />
</Box>


      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
          {/* Left Side: Dropdown and Apply Button */}
          <Box display="flex" alignItems="center">
          <FormControl size="small" sx={{ mr: 2, width: '100%' }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
    }}
  >
    <MenuItem value="" disabled>
      Download Invoices
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>

            <Button variant="contained" color="primary">
              APPLY
            </Button>
          </Box>

          {/* Right Side: Filter Buttons and Date Picker */}
          <Box display="flex" alignItems="center">
            {/* Filter Buttons */}
         

            {/* Date Range Picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DateRangePicker
    sx={{
      color: "#000", // Ensure the text color is black
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiInputBase-input': {
        color: "#000", // Set the input text color to black
      },
      backgroundColor:"#80A0FF",
      borderRadius: '8px'
    }}
    startText="Start"
    endText="End"
    value={dateRange}
    onChange={(newValue) => setDateRange(newValue)}
    renderInput={(startProps, endProps) => (
      <>
        <TextField
          {...startProps}
          size="small"
          InputProps={{
            style: { color: "#000" }, // Set label color to black
          }}
          sx={{
            mx: 1,
            fontFamily: "Noto Sans",
            fontSize: "14px",
            '& .MuiInputBase-input': {
              color: "#000", // Set the input text color to black
            },
          }}
        />
        <TextField
          {...endProps}
          size="small"
          InputProps={{
            style: { color: "#000" }, // Set label color to black
          }}
          sx={{
            mx: 1,
            fontFamily: "Noto Sans",
            fontSize: "14px",
            '& .MuiInputBase-input': {
              color: "#000", // Set the input text color to black
            },
            
          }}
        />
      </>
    )}
  />
</LocalizationProvider>


            {/* Filter By Button */}
            {/* <IconButton>
              <FilterList />
            </IconButton> */}
          </Box>
        </Box>

        
      <DataGrid
        rows={retailerrows}
        columns={retailercolumns}
        pageSize={12}
        checkboxSelection
        sx={{
            '& .MuiDataGrid-cell': {
              color: '#555555', // Set the text color for the cells
            },
            '& .MuiDataGrid-columnHeader': {
              color: '#FFFFFF',
              backgroundColor:"#5873C5" // Set the text color for the column headers
            },
            '& .MuiCheckbox-root': {
              color: '#555555', // Set the default checkbox color
              '&.Mui-checked': {
                color: '#555555', // Set the color when the checkbox is checked
              },
            },
          }}
      />

    </Box>

  {/* Spacer between the two tables */}
  <Box sx={{ mb: 20 }} />

    {/*list of inventory*/}
    <Box sx={{ height: 600, width: "100%", backgroundColor: "transparent", p: 2 }}>
        <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
      <Box marginBottom={2} sx={{display:"flex", width: "50%"}}>
        <Typography 
        sx={{
            fontFamily: "noto sans",
fontSize: "18px",
fontWeight: "500",
lineHeight: "28.8px",
textAlign: "left",
color:"#555555",
width:"30%",
display:"flex",
alignSelf:"center"
}}>List of Inventory</Typography>
  <TextField
    placeholder="Search by name, pin, mobile number"
    variant="outlined"
    size="small"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{color:"#999999"}} />
        </InputAdornment>
      ),
      style: {
        padding: "8px 0px 8px 12px",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",
        opacity: 1,
        color:"#999999",  // Ensure visibility
        height:"34px"
      },
    }}
  />
</Box>
      <Box  my={2} sx={{display:"flex", alignItems:"center"}}>
          {/* Left Side: Dropdown and Apply Button */}
          <Box display="flex" alignItems="center" sx={{mr:2, borderRadius:"20px"}}>
          <FormControl size="small" sx={{ mr: 2, width: '100%', borderRadius:"20px" }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
        borderRadius:"20px"
      },
    }}
  >
    <MenuItem value="" disabled>
      Download Invoices
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>

            <Button variant="contained" sx={{background:"#80A0FF", border: "1px solid #80A0FF", borderRadius:"8px"}}>
              APPLY
            </Button>
          </Box>

          {/* Right Side: Filter Buttons and Date Picker */}
          <Box display="flex" alignItems="center">
            {/* Filter Buttons */}
            <Box display="flex" alignItems="center" sx={{borderRadius:"8px"}}>
          <FormControl size="small" sx={{ mr: 2, width: '100%', borderRadius:"8px" }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
      borderRadius:"8px"
    }}
  >
    <MenuItem value="" disabled>
     Last 30 Days
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>
          </Box>


            {/* Filter By Button */}
            {/* <IconButton>
              <FilterList />
            </IconButton> */}
          </Box>
        </Box>
        </Box>

        
      <DataGrid
        rows={inventoryrows}
        columns={inventorycolumns}
        pageSize={12}
        checkboxSelection
        sx={{
            '& .MuiDataGrid-cell': {
              color: '#555555', // Set the text color for the cells
            },
            '& .MuiDataGrid-columnHeader': {
              color: '#FFFFFF',
              backgroundColor:"#5873C5" // Set the text color for the column headers
            },
            '& .MuiCheckbox-root': {
              color: '#555555', // Set the default checkbox color
              '&.Mui-checked': {
                color: '#555555', // Set the color when the checkbox is checked
              },
            },
          }}
      />

    </Box>

    </Container>



  );
};

export default RetailerDetails;
