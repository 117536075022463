import React, {useState} from 'react';
import {InputLabel, TableContainer, TableHead, TextField, InputAdornment, Box, Typography, Grid, FormControl, Select, MenuItem, Card, CardContent,Container,  Button, Paper, Avatar, Table, TableBody, TableRow, TableCell, Divider, Icon, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentCopy from '@mui/icons-material/ContentCopy'; // Import the copy icon

import OfferFinancingDialog from '../../components/OfferFinancingDialog';
import SettleInvoiceDialog from '../../components/SettleInvoiceDialog';
import ImageIcon from '@mui/icons-material/Image';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Label } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import { Visibility, FilterList } from "@mui/icons-material";
import { scaleLinear } from "d3-scale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { Chart } from "react-google-charts";
import maggi from "assets/maggi.png";



// Modify sales data to only include Latitude, Longitude, and Sales
const salesDataWithCoordinates = [
    ["Latitude", "Longitude", "Sales"],
    [12.9716, 77.5946, 272558], // Bangalore
    [31.6340, 74.8723, 120126], // Amritsar
    [17.3850, 78.4867, 100456], // Hyderabad
    [25.5941, 85.1376, 100001], // Patna
    [26.8467, 80.9462, 98558], // Lucknow
    [13.0827, 80.2707, 90501], // Chennai
    [22.7196, 75.8577, 89449], // Indore
    [32.5420, 76.3274, 88327], // Dalhousie
  ];


  const shopdata = [
    { month: 'Jan', shop1: 10000, shop2: 24000, shop3: 14000, shop4: 28000 },
    { month: 'Feb', shop1: 12000, shop2: 22000, shop3: 16000, shop4: 26000 },
    { month: 'Mar', shop1: 18000, shop2: 20000, shop3: 8000, shop4: 30000 },
    { month: 'Apr', shop1: 15000, shop2: 28000, shop3: 5000, shop4: 32000 },
    { month: 'May', shop1: 20000, shop2: 30000, shop3: 10000, shop4: 34000 },
    { month: 'Jun', shop1: 18000, shop2: 26000, shop3: 12000, shop4: 29000 },
    { month: 'Jul', shop1: 22000, shop2: 27000, shop3: 15000, shop4: 33000 },
    { month: 'Aug', shop1: 17000, shop2: 28000, shop3: 16000, shop4: 30000 },
    { month: 'Sep', shop1: 25000, shop2: 24000, shop3: 14000, shop4: 31000 },
    { month: 'Oct', shop1: 23000, shop2: 22000, shop3: 13000, shop4: 27000 },
    { month: 'Nov', shop1: 22000, shop2: 25000, shop3: 12000, shop4: 32000 },
    { month: 'Dec', shop1: 24000, shop2: 29000, shop3: 18000, shop4: 35000 },
  ];

const salesData = [
    { city: "Bangalore", sales: 272558, coordinates: [77.5946, 12.9716] },
    { city: "Amritsar", sales: 120126, coordinates: [74.8723, 31.634] },
    { city: "Hyderabad", sales: 100456, coordinates: [78.4867, 17.385] },
    { city: "Patna", sales: 100001, coordinates: [85.1376, 25.5941] },
    { city: "Lucknow", sales: 98558, coordinates: [80.9462, 26.8467] },
    { city: "Chennai", sales: 90501, coordinates: [80.2707, 13.0827] },
    { city: "Indore", sales: 89449, coordinates: [75.8577, 22.7196] },
    { city: "Dalhousie", sales: 88327, coordinates: [76.3274, 32.542] },
  ];
  
  // Define the color scale for the markers based on sales
  const salesScale = scaleLinear()
    .domain([80000, 300000]) // Adjust based on your data range
    .range(["yellow", "green"]);

const inventorycolumns = [
    { field: "productname", headerName: "Order ID",flex: 1},
    { field: "category", headerName: "Retailer Name", flex: 1 },
    { field: "brand", headerName: "SKU", flex: 1 },
    { field: "distributor", headerName: "Cost/Unit", flex: 1 },
    { field: "sku", headerName: "Customer Name", flex: 1},
    { field: "unitprice", headerName: "Order Date", flex: 1 },
    { field: "unitsold", headerName: "Units Sold", flex: 1 },
    { field: "totalsales", headerName: "Total Sales", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: () => (
        <IconButton>
          <Visibility style={{color:"#555555"}}/>
        </IconButton>
      ),
    }
  ];
  
  const inventoryrows = [
    {
      id: 1,
      productname: "#3245",
      category: "Arya Singh",
      brand: "Mommin Mohi",
      distributor: "12.01.2024",
      sku: "12.01.2024",
      status: "₹2,558",
      unitprice: "Delivered",
      unitsold: "Delivered",
      dailysales: "Delivered",
      totalsales: "Delivered",
    },
    {
      id: 2,
      productname: "#3245",
      category: "Arya Singh",
      brand: "Mommin Mohi",
      distributor: "12.01.2024",
      sku: "12.01.2024",
      status: "₹2,558",
      unitprice: "Delivered",
      unitsold: "Delivered",
      dailysales: "Delivered",
      totalsales: "Delivered",
    },
    // Add more rows here based on your data
  ];

  const stockcolumns = [
    { field: "productname", headerName: "Item ID",flex: 1},
    { field: "category", headerName: "Distributor Name", flex: 1 },
    { field: "brand", headerName: "City", flex: 1 },
    { field: "distributor", headerName: "Cost/Unit", flex: 1 },
    { field: "sku", headerName: "Available Stock", flex: 1},
    { field: "unitprice", headerName: "Sold Stock", flex: 1 },
    { field: "unitsold", headerName: "SKU", flex: 1 },
    { field: "totalsales", headerName: "Total Sales", flex: 1 },
  ];
  
  const stockrows = [
    {
      id: 1,
      productname: "#3245",
      category: "Arya Singh",
      brand: "Mommin Mohi",
      distributor: "12.01.2024",
      sku: "12.01.2024",
      status: "₹2,558",
      unitprice: "Delivered",
      unitsold: "Delivered",
      dailysales: "Delivered",
      totalsales: "Delivered",
    },
    {
      id: 2,
      productname: "#3245",
      category: "Arya Singh",
      brand: "Mommin Mohi",
      distributor: "12.01.2024",
      sku: "12.01.2024",
      status: "₹2,558",
      unitprice: "Delivered",
      unitsold: "Delivered",
      dailysales: "Delivered",
      totalsales: "Delivered",
    },
    // Add more rows here based on your data
  ];

  const cityNames = ["Bangalore", "Amritsar", "Hyderabad", "Patna", "Lucknow", "Chennai", "Indore", "Dalhousie"];


const InventoryAnalysis = () => {


    const [dateRange, setDateRange] = React.useState([null, null]);

    // Chart options for India map with markers
  const chartOptions = {
    region: "IN", // India region
    displayMode: "markers", // Show markers for coordinates
    resolution: "provinces", // Show states within India
    colorAxis: { colors: ["yellow", "green", "blue"] }, // Color range based on sales
    backgroundColor: "transaparent", // Background color for the chart
    datalessRegionColor: "#9A9A9A", // Color for regions without data
    defaultColor: "transparent", // Default marker color
    tooltip: { isHtml: true }, // Enable HTML tooltips
    chartArea: {
        top: 0,    // Remove space at the top
        left: 0,   // Remove space on the left
        right: 0,  // Remove space on the right
        bottom: 0  // Remove space at the bottom
      } 
  };

  // Transform data to add tooltips for city names
  const salesDataWithTooltips = salesDataWithCoordinates.map((row, index) => {
    if (index === 0) return [...row, { role: "tooltip", p: { html: true } }]; // Add a tooltip column header for the first row
    return [...row, `<div><strong>${cityNames[index - 1]}</strong><br/>Sales: ₹${row[2].toLocaleString()}</div>`]; // Add city name to tooltip
  });

    const mockData = {
        profile: {
          name: 'Kunal Sehgal',
          storeId: 'BRKCS776W',
          metrics: [
            { label: 'Revenue Generated', value: '₹1,725,548' },
            { label: 'Customers Acquired', value: '12457' },
            { label: 'Inventory Purchased', value: '₹725,548' },
            { label: 'Transactions Completed', value: '1,725' },
            { label: 'Gross Profit Generated', value: '₹1,00,548' },
            { label: 'Purchase Order Completed', value: '24515' },
          ],
          creditScore: 726,
          location: 'Thane',
          dob: 'Aug 14, 1988',
          designation: 'Sprint',
          mobileNumber: '+91 1234567890',
        },
        kyc: [
          { type: 'Aadhaar', side: 'Front', status: 'Not Found', action: 'Not Submitted' },
          { type: 'Aadhaar', side: 'Back', status: 'Aadhaar Card Back', action: 'Submitted' },
          { type: 'PAN', side: 'Front', status: 'PAN Card Front', action: 'Submitted' },
          { type: 'Canceled Check', side: 'Front', status: 'Not Found', action: 'Not Submitted' },
        ],
        salesData: [
          { month: 'Jan', sales: 300 },
          { month: 'Feb', sales: 500 },
          { month: 'Mar', sales: 200 },
          { month: 'Apr', sales: 278 },
          { month: 'May', sales: 390 },
          { month: 'Jun', sales: 450 },
        ],
        creditHistory: [
          { name: 'Ashok Lathar', location: 'Amritsar', amount: '₹7.5 Lakh' },
          { name: 'Madhan Tane', location: 'Thane', amount: '₹4.5 Lakh' },
          { name: 'Farsi Tyagi', location: 'Puducherry', amount: '₹3.1 Lakh' },
        ],
        transactionData: [
          { month: 'Jan', UPI: 500, Cash: 200, POS: 150, Credit: 100 },
          { month: 'Feb', UPI: 600, Cash: 300, POS: 200, Credit: 120 },
        ],
      };
      
        const columns = [
          { field: 'name', headerName: 'Name', flex:1},
          { field: 'location', headerName: 'Location', flex:1},
          { field: 'amount', headerName: 'Amount', flex:1 },
        ];
      
        const mockDataColumn = {
          creditHistory: [
            { id: 1, name: 'Ashok Lathar', location: 'Amritsar', amount: '₹7.5 Lakh' },
            { id: 2, name: 'Madhan Tane', location: 'Thane', amount: '₹4.5 Lakh' },
            { id: 3, name: 'Farsi Tyagi', location: 'Puducherry', amount: '₹3.1 Lakh' },
          ],
        };

        
  return (
    <Box sx={{ p: 4, bgcolor: '#F8F9FD' }}>

<Grid container spacing={2} sx={{ p: 4, bgcolor: '#F8F9FD' }}>
  {/* First Box */}
  <Grid item xs={8} sx={{ height: '100%' }}> {/* Adjust column size here */}
    
      <Box
      sx={{
        mb: 3,
        backgroundColor: 'white',
        borderRadius: "16px",
        boxShadow: 3,
        border: '1px solid #e0e0e0',
        color: 'black',
      }}
    >
      <Box
        sx={{
        
          backgroundColor: '#4267b2',
          borderRadius: "16px 16px 0 0",
          paddingX: '32px',
          paddingY:"16px",
          color: 'white',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Order Details
        </Typography>
      </Box>

<Box sx={{padding:"20px"}}>
      <Table sx={{ minWidth: 650}}>
  <TableBody>
    {/* Row for Location */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",  borderBottom:"1px solid #EEEEEE", width:"20%"}}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Product ID:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>#10654</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Product Name:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Maggi Magic Masala</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", width:"20%"}}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Product Code:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>12458</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Product Code:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>12458</Typography>
      </Box>
      </TableCell>
    </TableRow>

    {/* Row for Email Address */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>MRP:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>₹ 60</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Brand Name:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Maggi</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", width:"20%"}}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Pack Title:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>null</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Pack Unit:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>null</Typography>
      </Box>
      </TableCell>
    </TableRow>

    {/* Row for Direct Manager */}
    <TableRow>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px", borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Barcode:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>-----</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Category:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Staples</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Sub-Category</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Spices</Typography>
      </Box>
      </TableCell>
      <TableCell sx={{ color: "#555555", fontWeight: "400", fontSize: "16px", padding: "12px 16px",borderBottom:"1px solid #EEEEEE", width:"20%" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <Typography sx={{color:"#777777", p:0.5, fontSize:"14px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Description:</Typography>
      <Typography sx={{color:"#555555",p:0.5, fontSize:"16px", fontWeight:"400", fontFamily:"Noto Sans", lineHeight:"19.07px"}}>Spice Booster Magical Masala pack of 40 </Typography>
      </Box>
      </TableCell>
    </TableRow>

  </TableBody>
</Table>
</Box>

    </Box>
    </Grid>

 {/* Second Box with Icon */}
 <Grid item xs={4} sx={{ height: '100%' }}>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
       backgroundColor: 'white',
      borderRadius: '16px',
      boxShadow: 3,
      padding: 2,
    }}
  >
    {/* Directly use the ImageIcon without wrapping in Icon */}
    {/* <ImageIcon sx={{
        color: "green",
        width: '100%',  // Take full width of the Box
        height: '100%', // Take full height of the Box
        // maxWidth: 350,  // Limit maximum size
        // maxHeight: 350, // Limit maximum size
      }} /> */}
      <img 
    src={maggi} // Path to your image
    alt="maggi"
    style={{
      width: '100%',  // Take full width of the Box
      height: '100%', // Take full height of the Box
      objectFit: 'contain', // Maintain aspect ratio
    }}
    />
  </Box>
</Grid>

<Grid container spacing={2} style={{ marginTop: '20px' }}>
  {/* Business Sales */}
  <Grid item xs={12} md={8}>
    <Box sx={{display:"flex", justifyContent:"space-between"}}>
  <Typography variant="h6" sx={{color:"#404040", mb:2}}>Sales Trend Throughout The Year</Typography>
  <Box display="flex">
          <Box display="flex" alignItems="center" sx={{minwidth:"172px"}}>
          <FormControl size="small" sx={{ mr: 2, width: '100%', minwidth:"172px" }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
      borderRadius:"16px",
      minwidth:"172px"
    }}
  >
    <MenuItem value="" disabled>
      Last Year
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>
          </Box>
          </Box>
  </Box>



    <Card sx={{mt:1}}>
      <CardContent sx={{background: "linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)", border: "1px solid #F7F7F7"}}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={mockData.salesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" label={{ value: 'Duration', position: 'insideBottomRight', offset: -5 }} />
            <YAxis label={{ value: 'Sales in Crores', angle: -90, position: 'insideLeft' }}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
    
  </Grid>

  {/* sales */}
  <Grid item xs={12} md={4}>
    <Grid container spacing={1}>

    <Grid item xs={12} sm={12} >
        
    <Typography sx={{color:"#404040", fontSize:"18px", fontFamily: "Noto Sans",
fontWeight: "500", lineHeight: "28.8px", textAlign: "left"}}>
  Product History 
  <span style={{marginLeft:"5px", color:"#777777", fontFamily: "Noto Sans", fontSize: "12px", fontWeight: "400", lineHeight: "16.34px", textAlign: "left"}}>
    (Until last month)
  </span>
</Typography>


        </Grid>
      
        <Grid item xs={12} sm={12} >
         <Card sx={{background: "#FFFFFF", border: "1px solid #EEEEEE", borderRadius:"16px", height:"115px"}}>
  <CardContent sx={{height: "100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
  <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"100%"}}>
      <Typography variant="h6" sx={{color:"#000000", fontFamily: "Noto Sans", fontWeight:"600", fontSize:"16px", lineHeight:"21.79px", letterSpacing:"0.02em"}}>Total Sales</Typography>
      <Typography variant="h5" sx={{color:"#5873C5", fontFamily: "Noto Sans", fontWeight:"700", fontSize:"28px", lineHeight:"44.8px", letterSpacing:"0.02em"}}>₹2,62,090</Typography>
    </Box>

    <Box sx={{mr:3}}>
        <BarChartIcon sx={{color:"#FF808D", fontSize:"48px"}} />
      </Box>
    
  </CardContent>
</Card>

        </Grid>
     
      <Grid item xs={12} sm={12} >
       <Card sx={{background: "#FFFFFF", border: "1px solid #EEEEEE", borderRadius:"16px", height:"115px"}}>
<CardContent sx={{height: "100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
<Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"100%"}}>
    <Typography variant="h6" sx={{color:"#000000", fontFamily: "Noto Sans", fontWeight:"600", fontSize:"16px", lineHeight:"21.79px", letterSpacing:"0.02em"}}>Total Orders</Typography>
    <Typography variant="h5" sx={{color:"#5873C5", fontFamily: "Noto Sans", fontWeight:"700", fontSize:"28px", lineHeight:"44.8px", letterSpacing:"0.02em"}}>#35,784</Typography>
  </Box>

  <Box sx={{mr:3}}>
      <ShoppingBasketIcon sx={{color:"#FF808D", fontSize:"48px"}} />
    </Box>
  
</CardContent>
</Card>

      </Grid>
     
      <Grid item xs={12} sm={12} mb={0.1}>
       <Card sx={{background: "#FFFFFF", border: "1px solid #EEEEEE", borderRadius:"16px", height:"115px"}}>
<CardContent sx={{height: "100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
<Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"100%"}}>
    <Typography variant="h6" sx={{color:"#000000", fontFamily: "Noto Sans", fontWeight:"600", fontSize:"16px", lineHeight:"21.79px", letterSpacing:"0.02em"}}>Customer Boost</Typography>
    <Typography variant="h5" sx={{color:"#5873C5", fontFamily: "Noto Sans", fontWeight:"700", fontSize:"28px", lineHeight:"44.8px", letterSpacing:"0.02em"}}>+612</Typography>
  </Box>

  <Box sx={{mr:3}}>
      <GroupsIcon sx={{color:"#FF808D", fontSize:"48px"}} />
    </Box>
  
</CardContent>
</Card>

      </Grid>
  </Grid>
  </Grid>
</Grid>



<Grid item xs={12} sm={12} mb={10}>
<Box sx={{ height: 600, width: "100%", backgroundColor: "transparent" }}>
        <Box>
      <Box marginBottom={1} sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width: "100%"}}>
        <Typography 
        sx={{
            fontFamily: "noto sans",
fontSize: "18px",
fontWeight: "500",
lineHeight: "28.8px",
textAlign: "left",
color:"#555555",
width:"20%",
display:"flex",
alignSelf:"center"
}}>Sales Order History</Typography>
  <TextField
    placeholder="Search by Order ID"
    variant="outlined"
    size="small"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{color:"#999999"}} />
        </InputAdornment>
      ),
      style: {
        padding: "8px 0px 8px 12px",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",
        opacity: 1,
        color:"#999999",  // Ensure visibility
        height:"34px"
      },
    }}
  />
</Box>
\
        </Box>

        
      <DataGrid
        rows={inventoryrows}
        columns={inventorycolumns}
        pageSize={12}
        // checkboxSelection
        sx={{
            '& .MuiDataGrid-cell': {
              color: '#555555', // Set the text color for the cells
            },
            '& .MuiDataGrid-columnHeader': {
              color: '#FFFFFF',
              backgroundColor:"#5873C5" // Set the text color for the column headers
            },
            '& .MuiCheckbox-root': {
              color: '#555555', // Set the default checkbox color
              '&.Mui-checked': {
                color: '#555555', // Set the color when the checkbox is checked
              },
            },
          }}
      />

    </Box>
</Grid>


  {/* Spacer between the two tables */}
  {/* <Box sx={{ mb: 85 }} /> */}


<Grid item xs={12} sm={12}>
<Box sx={{ padding: "20px", borderRadius:"16px", background: "linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)", border: "1px solid #F7F7F7", boxShadow:"10px 10px 40px 0px #D2D2D299,-10px -10px 20px 0px #FFFFFF" }}>
      {/* Date Range and Title */}
      <Box display="flex" justifyContent="space-between" alignItems="center"  sx={{color:"black"}}>
        <Typography variant="h6">Highest Sales by City</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DateRangePicker
    sx={{
      color: "#000", // Ensure the text color is black
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiInputBase-input': {
        color: "#000", // Set the input text color to black
      },
      backgroundColor:"#80A0FF",
      borderRadius: '8px',
      height:"30px",
      width:"250px"
    }}
    // startText="Start"
    // endText="End"
    value={dateRange}
    onChange={(newValue) => setDateRange(newValue)}
    renderInput={(startProps, endProps) => (
      <>
        <TextField
          {...startProps}
          size="small"
          InputProps={{
            style: { color: "#000" }, // Set label color to black
          }}
          sx={{
            mx: 1,
            fontFamily: "Noto Sans",
            fontSize: "14px",
            '& .MuiInputBase-input': {
              color: "#000", // Set the input text color to black
            },
            color:"#000000"
          }}
        />
        <TextField
          {...endProps}
          size="small"
          InputProps={{
            style: { color: "#000" }, // Set label color to black
          }}
          sx={{
            mx: 1,
            fontFamily: "Noto Sans",
            fontSize: "14px",
            '& .MuiInputBase-input': {
              color: "#000", // Set the input text color to black
            },
            
          }}
        />
      </>
    )}
  />
</LocalizationProvider>
      </Box>

      {/* Sales Data and Map */}
      <Box sx={{ display: "flex", justifyContent: "space-between", paddingTop: "20px" }}>
        {/* Sales Table */}
        <Box>
          <TableContainer sx={{ width: 400, background:"#FFFFFF",fontSize:"14px", borderRadius:"12px", border: "1px solid #EEEEEE" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#3f51b5", color: "white", padding: "10px", textAlign: "center" }}>
                  <TableCell>City</TableCell>
                  <TableCell>Sales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {salesData.map((row) => (
                  <TableRow  key={row.city}>
                    <TableCell sx={{color:"#555555", border:"none"}}>{row.city}</TableCell>
                    <TableCell sx={{color:"#555555", border:"none"}}>{`₹${row.sales.toLocaleString()}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Map with Markers */}
        <Box sx={{ margin: 0, padding: 0 }}>
                <Chart
                  chartType="GeoChart"
                  width="600px"
                  height="100%"
                  data={salesDataWithTooltips}
                  options={chartOptions}
                />
              </Box>
      </Box>
    </Box>
</Grid>


<Grid item xs={12} sm={12}  mb={10}>
<Box sx={{ height: 600, width: "100%", backgroundColor: "transparent" }}>
        <Box>
      <Box marginBottom={1} sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width: "100%"}}>
        <Typography 
        sx={{
            fontFamily: "noto sans",
fontSize: "18px",
fontWeight: "500",
lineHeight: "28.8px",
textAlign: "left",
color:"#555555",
width:"20%",
display:"flex",
alignSelf:"center"
}}>Current Stock</Typography>
  <TextField
    placeholder="Search by Order ID"
    variant="outlined"
    size="small"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{color:"#999999"}} />
        </InputAdornment>
      ),
      style: {
        padding: "8px 0px 8px 12px",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",
        opacity: 1,
        color:"#999999",  // Ensure visibility
        height:"34px"
      },
    }}
  />
</Box>
        </Box>

        
      <DataGrid
        rows={stockrows}
        columns={stockcolumns}
        pageSize={12}
        // checkboxSelection
        sx={{
            '& .MuiDataGrid-cell': {
              color: '#555555', // Set the text color for the cells
            },
            '& .MuiDataGrid-columnHeader': {
              color: '#FFFFFF',
              backgroundColor:"#5873C5" // Set the text color for the column headers
            },
            '& .MuiCheckbox-root': {
              color: '#555555', // Set the default checkbox color
              '&.Mui-checked': {
                color: '#555555', // Set the color when the checkbox is checked
              },
            },
          }}
      />

    </Box>
</Grid>

<Grid item xs={12} sm={12}>
    <Box sx={{display:"flex", justifyContent:"space-between"}}>
    <Typography variant="h6" align="left" sx={{color:"#404040", fontSize:"18px", mb:2}}>
    Total Item sold per day
      </Typography>
      <Box display="flex">
          <Box display="flex" alignItems="center" sx={{minwidth:"172px"}}>
          <FormControl size="small" sx={{ mr: 2, width: '100%', minwidth:"172px" }}>
  <Select
    value="" // Use an empty string for the placeholder
    onChange={() => {}}
    displayEmpty // Allows the placeholder to show
    sx={{
      color: "#80A0FF",
      border: "1px solid #80A0FF",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      '& .MuiSelect-select': {
        color: "#80A0FF", // Set the text color for the selected item
      },
      '& .MuiInputBase-root': {
        border: "1px solid #80A0FF", // Set the border color
      },
      borderRadius:"16px",
      minwidth:"172px"
    }}
  >
    <MenuItem value="" disabled>
      Yesterday
    </MenuItem>
    <MenuItem value="csv">CSV</MenuItem>
    <MenuItem value="pdf">PDF</MenuItem>
  </Select>
</FormControl>
          </Box>
          </Box>
    </Box>
<Box sx={{ p: 3, borderRadius: '16px', background: "linear-gradient(156.65deg, #ECECEC 1.2%, #FFFFFF 98.95%)", border: "1px solid #F7F7F7", boxShadow: "10px 10px 40px 0px #D2D2D299, -10px -10px 20px 0px #FFFFFF" }}>
      
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={shopdata} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month">
          <Label value="Month →" offset={-10} position="insideBottomLeft" sx={{ color:"#404040"}} />
          </XAxis>
          <YAxis>
          <Label value="Av. Amount Spent →" angle = "-90" offset={-7} position="insideBottomLeft" sx={{ color:"#404040"}} />
          </YAxis>
          <Tooltip />
          <Legend align="right" verticalAlign="bottom" />
          <Line type="monotone" dataKey="shop1" stroke="#82ca9d" strokeWidth={2} />
          <Line type="monotone" dataKey="shop2" stroke="#8884d8" strokeWidth={2} />
          <Line type="monotone" dataKey="shop3" stroke="#ffc658" strokeWidth={2} />
          <Line type="monotone" dataKey="shop4" stroke="#413ea0" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
</Grid>





  </Grid>

    </Box>
  );
};

export default InventoryAnalysis;
