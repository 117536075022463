import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Avatar,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import {
  DashboardOutlined,
  ReceiptOutlined,
  LocalOfferOutlined,
  StoreOutlined,
  PeopleAltOutlined,
  AccountBalanceWalletOutlined,
  SettingsOutlined,
  SupportOutlined,
  ExpandLess,
  ExpandMore,
  CheckBox,
  Check,
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankRounded,
  CheckCircleOutlineRounded,
} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import amanafin_logo from "assets/compliance_wt.png";
import cubefin_logo from "assets/cubefin.png";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const [openLoans, setOpenLoans] = useState(false);

  const handleLoansClick = () => {
    setOpenLoans(!openLoans);
  };

  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#1E1E1E", // Dark background like the image
        },
      }}
      variant={isNonMobile ? "persistent" : "temporary"}
      anchor="left"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
    >
      {/* Logo Section */}
      <Box p={2} display="flex" justifyContent="center">
        <img
          src= {amanafin_logo}
          alt="Logo"
          style={{ width: "150px", height: "auto" }}
        />
      </Box>

      <Divider sx={{ my: 2 }} /> {/* Adds vertical spacing */}

      {/* Menu Items */}
      <List>
        {/* <ListItem button onClick={() => navigate("/dashboard")}>
          <ListItemIcon>
            <DashboardOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" sx={{ color: "#FFFFFF" }} />
        </ListItem> */}

        <ListItem button onClick={() => navigate("/invoiceanalysis")}>
          <ListItemIcon>
            <ReceiptOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Transactions" sx={{ color: "#FFFFFF" }} />
        </ListItem>

        {/* <ListItem button onClick={() => navigate("/financialoffers")}>
          <ListItemIcon>
            <LocalOfferOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Financing Offers" sx={{ color: "#FFFFFF" }} />
        </ListItem>

        <ListItem button onClick={() => navigate("/retailers")}>
          <ListItemIcon>
            <StoreOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Retailers" sx={{ color: "#FFFFFF" }} />
        </ListItem>

        <ListItem button onClick={() => navigate("/suppliers")}>
          <ListItemIcon>
            <PeopleAltOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Suppliers" sx={{ color: "#FFFFFF" }} />
        </ListItem> */}

        {/* Loans Portfolio with Expandable Submenu */}
        {/* <ListItem button onClick={handleLoansClick}>
          <ListItemIcon>
            <AccountBalanceWalletOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Loans Portfolio" sx={{ color: "#FFFFFF" }} />
          {openLoans ? <ExpandLess style={{ color: "#FFFFFF" }} /> : <ExpandMore style={{ color: "#FFFFFF" }} />}
        </ListItem>
        <Collapse in={openLoans} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate("/exploreportfolio")} sx={{ pl: 4 }}>
              <ListItemText primary="Explore Portfolio" sx={{ color: "#FFFFFF" }} />
            </ListItem>
            <ListItem button sx={{ pl: 4 }}>
              <ListItemText primary="Create Portfolio" sx={{ color: "#FFFFFF" }} />
            </ListItem>
            <ListItem button onClick={() => navigate("/myoffers")} sx={{ pl: 4 }}>
              <ListItemText primary="My Offers" sx={{ color: "#FFFFFF" }} />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => navigate("/settings")}>
          <ListItemIcon>
            <SettingsOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Settings" sx={{ color: "#FFFFFF" }} />
        </ListItem>

        <ListItem button onClick={() => navigate("/support")}>
          <ListItemIcon>
            <SupportOutlined style={{ color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText primary="Support" sx={{ color: "#FFFFFF" }} />
        </ListItem>*/}
      </List> 

      {/* Profile Section */}
      {/* <Box position="absolute" bottom="16px" width="100%" p={2}>
        <ListItem button onClick={() => navigate("/profile")} sx={{ backgroundColor: "#F7F7F7", borderRadius: "16px", color:"#555555" }}>
        <IconButton sx={{color:"#555555"}}>
            <PersonIcon />
          </IconButton> */}
          {/* <ListItemIcon>
            <Avatar alt={user?.name || "Default User"} src={user?.avatar || "/default-avatar.png"} />
          </ListItemIcon> */}
          {/* <ListItemText primary={user?.name || "User Name"} />
          <IconButton sx={{color:"#555555"}}>
            <CheckCircleOutlineRounded style={{color:'#80A0FF'}}/>
          </IconButton>
          
        </ListItem>
      </Box> */}
    </Drawer>
  );
};

export default Sidebar;
