import React, { useMemo, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Theme
import { themeSettings } from "theme";

// Scenes
import {
  Layout,
  Dashboard,
  Customers,
  Geography,
  Overview,
  Daily,
  Monthly,
  Breakdown,
  Admin,
  Performance,
} from "scenes";
import LoginPage from "scenes/login";
import SignUp from "scenes/registration";
import Distributors from "scenes/products";
import Retailers from "scenes/retailers";
import Supplier from "scenes/supplier";
import InvoiceAnalysis from "scenes/invoiceanalysis";
import FinancialOffers from "scenes/financialoffers";
import InvoiceCashFlowAnalysis from "scenes/invoicecashflowanalysis";
import FinancialOfferDetails from "scenes/financialofferdetails";
import RetailerDetails from "scenes/retailerdetails";
import SupplierDetails from "scenes/supplierdetails";
import InventoryAnalysis from "scenes/inventorydetails";
import ExplorePortfolio from "scenes/exploreportfolio";
import PortfolioDetails from "scenes/portfoliodetails";
import MyOffers from "scenes/myoffers";
import LogDetails from "scenes/logdetails";
import Settings from "scenes/settings";
import Support from "scenes/Support";
import Profile from "scenes/Profile";
import { Helmet } from "react-helmet";


// App
const App = () => {

  useEffect(() => {
    const projectType = process.env.REACT_APP_PROJECT_TYPE;
    console.log("REACT_APP_PROJECT_TYPE:", projectType);  // Debugging line
    document.title = projectType || "";
  }, []);
  


  // Dark/Light mode
  const mode = useSelector((state) => state.global.mode);
  // theme setting
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <Helmet>
      <title>{process.env.REACT_APP_PROJECT_TYPE.toUpperCase()}</title>
      </Helmet>
      <BrowserRouter>
        {/* Theme Provider */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {/* Routes */}
           
              {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<LoginPage />} />
              {/* <Route path="/signup" element={<SignUp />} /> */}
              <Route element={<Layout />}>
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              {/* <Route path="/distributors" element={<Distributors />} /> */}
              {/* <Route path="/customers" element={<Customers />} /> */}
              {/* <Route path="/retailers" element={<Retailers />} /> */}
              {/* <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="/suppliers" element={<Supplier />} /> */}
              <Route path="/invoiceanalysis" element={<InvoiceAnalysis />} />
              {/* <Route path="/financialoffers" element={<FinancialOffers />} /> */}
              <Route path="/:page/invoicecashflowanalysis" element={<InvoiceCashFlowAnalysis />} />
              {/* <Route path="/:page/financialofferdetails" element={<FinancialOfferDetails />} />
              <Route path="/:page/retailerdetails" element={<RetailerDetails />} />
              <Route path="/:page/:secondpage/retailerdetails" element={<RetailerDetails />} />
              <Route path="/:page/supplierdetails" element={<SupplierDetails />} />
              <Route path="/:page/:secondpage/supplierdetails" element={<RetailerDetails />} />
              <Route path="/:page/inventorydetails" element={<InventoryAnalysis />} />
              <Route path="/:page/:secondpage/inventorydetails" element={<InventoryAnalysis />} />
              <Route path="/:page/:secondpage/:thirdpage/inventorydetails" element={<InventoryAnalysis />} />
              <Route path="/exploreportfolio" element={<ExplorePortfolio />} />
              <Route path="/:page/portfoliodetails" element={<PortfolioDetails />} />
              <Route path="/myoffers" element={<MyOffers />} />
              <Route path="/:page/:secondpage/myoffers" element={<MyOffers />} />
              <Route path="/:page/logdetails" element={<LogDetails />} />
              <Route path="/:page/:secondpage/:thirdpage/logdetails" element={<LogDetails />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/support" element={<Support />} />
              <Route path="/profile" element={<Profile />} /> */}

            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
